.cloud1 {
	height:100px;
	width:300px;
	position:absolute;
	left:75%;
	top:35%;
	display:inline-block;
	background-image:url('../Images/cloud1.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.cloud2 {
	height:200px;
	width:350px;
	position:absolute;
	left:10%;
	top:5%;
	display:inline-block;
	background-image:url('../Images/cloud2.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.cloud3 {
	height:50px;
	width:150px;
	position:absolute;
	left:60%;
	display:inline-block;
	background-image:url('../Images/cloud3.png');
    background-repeat: no-repeat;
    background-size: contain;
}


.bandoncloud { 
	height:180px;
	width:480px;
	position:absolute;
	left:68%;
	top:2%;
	display:inline-block;
	background-image:url('../Images/bandoncloud0.png');
    background-repeat: no-repeat;
    background-size: contain;	
}

.bandoncloud:hover {
	background-image:url('../Images/bandoncloud1.png');
    background-repeat: no-repeat;
    background-size: contain;		
}